import { createAsync, useParams } from '@solidjs/router';
import { Suspense, Show, For } from 'solid-js';
import { BreadcrumbItem, Breadcrumbs, Container, Heading, Link, Page, Picture, Section } from '@troon/ui';
import dayjs from '@troon/dayjs';
import { createContentfulListRequest, createContentfulRequest } from '../../../api/content/[model]/_client';
import { RichText } from '../../../../components/rich-text';

export default function DEIArticleGroup() {
	const params = useParams<{ 'dei-article-group': string }>();

	const theme = createAsync(async () => getTheme(params['dei-article-group']), { deferStream: true });
	const articles = createAsync(async () => (theme()?.sys ? getArticles({ themeId: theme()!.sys.id }) : undefined), {
		deferStream: true,
	});

	return (
		<Suspense>
			<Show when={theme()}>
				{(theme) => (
					<>
						<Container class="mt-4">
							<Breadcrumbs>
								<BreadcrumbItem href="/about">About Troon</BreadcrumbItem>
								<BreadcrumbItem href="/about/dei">Diversity, Equity, and Inclusion</BreadcrumbItem>
								<BreadcrumbItem href={`/about/dei/${theme()?.fields.slug}`}>{theme()?.fields.title}</BreadcrumbItem>
							</Breadcrumbs>
						</Container>
						<Container>
							<Page>
								<Section>
									<Heading as="h1">{theme().fields.title}</Heading>
									<p class="font-medium text-brand-500">
										{theme().fields.startMonth}
										<Show when={theme().fields.startMonth !== theme().fields.endMonth}>
											{' '}
											{theme().fields.startDay} – {theme().fields.endMonth}
											{theme().fields.endDay}
										</Show>
									</p>
									<Show when={theme().fields.description}>{(doc) => <RichText document={doc()} />}</Show>

									<div class="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
										<For each={articles()?.items}>
											{(release, i) => (
												<article class="group relative flex flex-col gap-2">
													<div class="overflow-hidden rounded">
														<Show when={release.fields.heroImage?.fields}>
															{(image) => (
																<Picture
																	preload={i() < 3}
																	src={image().file?.url}
																	alt={image().description ?? image().title ?? ''}
																	sizes={[[480, 270]]}
																	class="size-full group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
																	crop="face"
																	loading={i() < 6 ? 'eager' : 'lazy'}
																/>
															)}
														</Show>
													</div>
													<Link
														href={`/about/dei/${theme()?.fields.slug}/${release.fields.slug}`}
														class="block text-lg font-semibold after:absolute after:inset-0"
													>
														{release.fields.title}
													</Link>
													<time class="text-sm">
														{dayjs(release.fields.publishDate, 'America/Phoenix').format('MMMM D, YYYY')}
													</time>
												</article>
											)}
										</For>
									</div>
								</Section>
							</Page>
						</Container>
					</>
				)}
			</Show>
		</Suspense>
	);
}

const getTheme = createContentfulRequest('dei-themes');
const getArticles = createContentfulListRequest('dei');
